.nae {
    margin-bottom: 30px;
}

@media (min-width: 798px) {
    .nae {
        padding: 20px 18%;
    }
}

